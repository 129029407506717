// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import companies from './companies'
import employee from './employee'
import survey from './survey'
import alert from './alert'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  companies,
  employee,
  survey,
  alert
})

export default rootReducer
